import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step7 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError20, setError20] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema19 = yup
		.object({
			['График работы бизнес ассистентов']: yup.string(),
			['Чем занимается отдел дизайнеров?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema19),
		defaultValues: {
			['График работы бизнес ассистентов']: data['График работы бизнес ассистентов'],
			['Что подразумевает услуга «Бухгалтерского обслуживания»?']: data['Что подразумевает услуга «Бухгалтерского обслуживания»?'],
			['Чем занимается отдел дизайнеров?']: data['Чем занимается отдел дизайнеров?'],
		},
	});


	const ckbox20 = watch('Что подразумевает услуга «Бухгалтерского обслуживания»?');

	useEffect(() => {
		if (!ckbox20?.length) {
			setError20(true);
		} else {
			setError20(false);
		}
	}, [ckbox20]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError20) {
			dispatch(setAddStep(8));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(7));
		}
	};

	return (
		<div className={s.step7}>
			<div className={s.wrapper_heading}>
				<h5>Роли. Директор. Skilla</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>19. График работы бизнес ассистентов</h4>

					<div className={s.question19}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question19,
								})}
								{...register('График работы бизнес ассистентов')}
								id='exam191'
								type='radio'
								name='График работы бизнес ассистентов'
								autoComplete='off'
								value='Круглосуточно'
							/>

							<label htmlFor='exam191' className={s.label}>
								Круглосуточно
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question19,
								})}
								{...register('График работы бизнес ассистентов')}
								id='exam192'
								type='radio'
								name='График работы бизнес ассистентов'
								autoComplete='off'
								value='По будням с 9:00 до 19:00 и по выходным с 9:00 до 18:00 (время МСК)'
							/>
							<label htmlFor='exam192' className={s.label}>
								По будням с 9:00 до 19:00 и по выходным с 9:00 до 18:00 (время МСК)
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question19,
								})}
								{...register('График работы бизнес ассистентов')}
								id='exam193'
								type='radio'
								name='График работы бизнес ассистентов'
								autoComplete='off'
								value='Ежедневно с 9:00 до 19:00 (время МСК)'
							/>
							<label htmlFor='exam193' className={s.label}>
								Ежедневно с 9:00 до 19:00 (время МСК)
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question19,
								})}
								{...register('График работы бизнес ассистентов')}
								id='exam194'
								type='radio'
								name='График работы бизнес ассистентов'
								autoComplete='off'
								value='По будням с 9:00 до 19:00 (по местному времени Партнера)'
							/>
							<label htmlFor='exam194' className={s.label}>
								По будням с 9:00 до 19:00 (по местному времени Партнера)
							</label>
						</div>
					</div>

					<p className={s.errorText}>
						{errors['График работы бизнес ассистентов'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						20. Что подразумевает услуга «Бухгалтерского обслуживания»?
					</h4>

					<div className={s.question20}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question20,
								})}
								{...register('Что подразумевает услуга «Бухгалтерского обслуживания»?')}
								id='exam201'
								type='checkbox'
								name='Что подразумевает услуга «Бухгалтерского обслуживания»?'
								autoComplete='off'
								value='Добавление чеков и актов по расходам в разделе "Покупки"' />
							<label htmlFor='exam201' className={s.label}>
								Добавление чеков и актов по расходам в разделе "Покупки"
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question20,
								})}
								{...register('Что подразумевает услуга «Бухгалтерского обслуживания»?')}
								id='exam202'
								type='checkbox'
								name='Что подразумевает услуга «Бухгалтерского обслуживания»?'
								autoComplete='off'
								value='Подготовка и сдача ежемесячной и ежеквартальной отчетности ООО и ИП' />
							<label htmlFor='exam202' className={s.label}>
								Подготовка и сдача ежемесячной и ежеквартальной отчетности ООО и ИП
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question20,
								})}
								{...register('Что подразумевает услуга «Бухгалтерского обслуживания»?')}
								id='exam203'
								type='checkbox'
								name='Что подразумевает услуга «Бухгалтерского обслуживания»?'
								autoComplete='off'
								value='Подсчет заработной платы сотрудников на трудовых договорах' />
							<label htmlFor='exam203' className={s.label}>
								Подсчет заработной платы сотрудников на трудовых договорах
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question20,
								})}
								{...register('Что подразумевает услуга «Бухгалтерского обслуживания»?')}
								id='exam204'
								type='checkbox'
								name='Что подразумевает услуга «Бухгалтерского обслуживания»?'
								autoComplete='off'
								value='Создание первичных документов в системе (счета, акты и т.д.)' />
							<label htmlFor='exam204' className={s.label}>
								Создание первичных документов в системе (счета, акты и т.д.)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question20,
								})}
								{...register('Что подразумевает услуга «Бухгалтерского обслуживания»?')}
								id='exam205'
								type='checkbox'
								name='Что подразумевает услуга «Бухгалтерского обслуживания»?'
								autoComplete='off'
								value='Помощь в составлении ответов по запросам налоговой' />
							<label htmlFor='exam205' className={s.label}>
								Помощь в составлении ответов по запросам налоговой
							</label>
						</div>

						<p className={s.errorText2}>
							{isError20 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>21. Чем занимается отдел дизайнеров?</h4>

					<div className={s.question21}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question21,
								})}
								{...register('Чем занимается отдел дизайнеров?')}
								id='exam211'
								type='radio'
								name='Чем занимается отдел дизайнеров?'
								autoComplete='off'
								value='Предоставляет готовые распечатанные макеты'
							/>
							<label htmlFor='exam211' className={s.label}>
								Предоставляет готовые распечатанные макеты
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question21,
								})}
								{...register('Чем занимается отдел дизайнеров?')}
								id='exam212'
								type='radio'
								name='Чем занимается отдел дизайнеров?'
								autoComplete='off'
								value='Изготавливает макеты в рамках брендбука в электронном виде для печати'
							/>
							<label htmlFor='exam212' className={s.label}>
								Изготавливает макеты в рамках брендбука в электронном виде для печати
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question21,
								})}
								{...register('Чем занимается отдел дизайнеров?')}
								id='exam213'
								type='radio'
								name='Чем занимается отдел дизайнеров?'
								autoComplete='off'
								value='Создает креативы для партнеров по их идеям без технического задания'
							/>
							<label htmlFor='exam213' className={s.label}>
								Создает креативы для партнеров по их идеям без технического задания
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question21,
								})}
								{...register('Чем занимается отдел дизайнеров?')}
								id='exam214'
								type='radio'
								name='Чем занимается отдел дизайнеров?'
								autoComplete='off'
								value='Создает макеты для любых личных нужд партнера'
							/>
							<label htmlFor='exam214' className={s.label}>
								Создает макеты для любых личных нужд партнера
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['Чем занимается отдел дизайнеров?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
					<Button callback={() => dispatch(setAddStep(6))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 7 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step7;
