import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step32 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError91, setError91] = useState(false);
    const [isError92, setError92] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		defaultValues: {
			['Сколько стоит СЕО-продвижение?']: data['Сколько стоит СЕО-продвижение?'],
			['Способы внесения оплаты за услуги по Договору?']: data['Способы внесения оплаты за услуги по Договору?'],
		},
	});

	
	const ckbox91 = watch('Сколько стоит СЕО-продвижение?');
    const ckbox92 = watch('Способы внесения оплаты за услуги по Договору?');
	
	useEffect(() => {
		if (!ckbox91?.length) {
			setError91(true);
		} else {
			setError91(false);
		}
	}, [ckbox91]);

    useEffect(() => {
		if (!ckbox92?.length) {
			setError92(true);
		} else {
			setError92(false);
		}
	}, [ckbox92]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError91 && !isError92) {
			dispatch(setAddStep(33));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(32));
		}
	};

	return (
		<div className={s.step32}>
      <div className={s.wrapper_heading}>
			  <h5>Оплаты по Договору</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					91. Сколько стоит СЕО-продвижение?
					</h4>

					<div className={s.question91}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question91,
								})}
								{...register('Сколько стоит СЕО-продвижение?')}
								id='exam911'
								type='checkbox'
								name='Сколько стоит СЕО-продвижение?'
								autoComplete='off'
								value='31 500 рублей - обычное продвижение (результаты через 9-12 месяцев)'/>
							<label htmlFor='exam911' className={s.label}>
							31 500 рублей - обычное продвижение (результаты через 9-12 месяцев)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question91,
								})}
								{...register('Сколько стоит СЕО-продвижение?')}
								id='exam912'
								type='checkbox'
								name='Сколько стоит СЕО-продвижение?'
								autoComplete='off'
								value='41 500 рублей  - экспресс продвижение (результаты через 6-9 месяцев)'/>
							<label htmlFor='exam912' className={s.label}>
							41 500 рублей  - экспресс продвижение (результаты через 6-9 месяцев)
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question91,
								})}
								{...register('Сколько стоит СЕО-продвижение?')}
								id='exam913'
								type='checkbox'
								name='Сколько стоит СЕО-продвижение?'
								autoComplete='off'
								value='Нет фиксированной оплаты, деньги списываются со счета за каждый клик по объявлению'/>
							<label htmlFor='exam913' className={s.label}>
							Нет фиксированной оплаты, деньги списываются со счета за каждый клик по объявлению
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question91,
								})}
								{...register('Сколько стоит СЕО-продвижение?')}
								id='exam914'
								type='checkbox'
								name='Сколько стоит СЕО-продвижение?'
								autoComplete='off'
								value='55 500 рублей  - ТОП- продвижение (результаты через 1 месяц)'/>
							<label htmlFor='exam914' className={s.label}>
							55 500 рублей  - ТОП- продвижение (результаты через 1 месяц)
							</label>
						</div>

						<p className={s.errorText2}>
							{isError91 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					92. Способы внесения оплаты за услуги по Договору?
					</h4>

					<div className={s.question92}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question92,
								})}
								{...register('Способы внесения оплаты за услуги по Договору?')}
								id='exam921'
								type='checkbox'
								name='Способы внесения оплаты за услуги по Договору?'
								autoComplete='off'
								value='Через CRM систему, по кнопке Оплата'/>
							<label htmlFor='exam921' className={s.label}>
							Через CRM систему, по кнопке Оплата
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question92,
								})}
								{...register('Способы внесения оплаты за услуги по Договору?')}
								id='exam922'
								type='checkbox'
								name='Способы внесения оплаты за услуги по Договору?'
								autoComplete='off'
								value='По реквизитам Договора'/>
							<label htmlFor='exam922' className={s.label}>
							По реквизитам Договора
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question92,
								})}
								{...register('Способы внесения оплаты за услуги по Договору?')}
								id='exam923'
								type='checkbox'
								name='Способы внесения оплаты за услуги по Договору?'
								autoComplete='off'
								value='Через банкомат'/>
							<label htmlFor='exam923' className={s.label}>
							Через банкомат
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question92,
								})}
								{...register('Способы внесения оплаты за услуги по Договору?')}
								id='exam924'
								type='checkbox'
								name='Способы внесения оплаты за услуги по Договору?'
								autoComplete='off'
								value='По счету'/>
							<label htmlFor='exam924' className={s.label}>
							По счету
							</label>
						</div>

						<p className={s.errorText2}>
							{isError92 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>
				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(31))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 32 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step32;
