import cn from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLkExam, getUser } from '../../../../../Api'
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector'
import { setAddUser } from '../../../../../store/reducer/lk/authChech/slice'
import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector'
import { setAddStatus, setAddStep, setAddIncorrect } from '../../../../../store/reducer/lk/formExam/slice'
import { selectLKSidebar } from '../../../../../store/reducer/lk/sidebar/selector'
import s from './done.module.scss'
import IncorrectAnswer from './IncorrectAnswer'

function Done({clientWithoutCours}) {
  const [width, setWidth] = useState(1440)
  const [score, setScore] = useState(JSON.parse(localStorage.getItem('scoreExam')) || '')
  const { user } = useSelector(selectLKAuth)
  const [statusForm, setStatusForm] = useState(JSON.parse(localStorage.getItem('statusExam')) || '')
  const { step, status, incorrect } = useSelector(selectLkFormExam)
  const { socketData } = useSelector(selectLKSidebar)
  const dispatch = useDispatch()
  console.log(status, incorrect)

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    getUser(user?.user?.code).then(({ data }) => {
      dispatch(setAddUser(data))
      localStorage.setItem('user', JSON.stringify(data))
    })
  }, [])

  useEffect(() => {
    localStorage.setItem('stepLkExam', JSON.stringify(step))
  }, [])

  useEffect(() => {
    setTimeout(() => {
      getLkExam(user?.user?.code)
        .then((res) => {
          const data = res.data
          setStatusForm(data.exam.status)
          localStorage.setItem('statusExam', JSON.stringify(data.exam.status))
          setScore(data.exam.scores)
          localStorage.setItem('scoreExam', JSON.stringify(data.exam.scores))
          dispatch(setAddIncorrect(JSON.parse(data.exam.answers)))
          localStorage.setItem('inccorectExam', data.exam.answers)
          dispatch(setAddStatus(data))
          localStorage.setItem('statusExam', JSON.stringify(data))
        })

        .catch((e) => {
          console.log(e)
        })
    }, 100)

    dispatch(setAddStep(36))
  }, [user?.user?.code])

  useEffect(() => {
    if (statusForm === 'waiting') {
      localStorage.removeItem('stepLkExam')
      dispatch(setAddStep(0))
      localStorage.removeItem('inccorectExam')
      localStorage.removeItem('dataExam')
      localStorage.removeItem('statusExam')
    }
  }, [statusForm])

  return (
    <div className={s.done}>
      <div className={s.wrapper_title}>
        {statusForm === 'failed' && <img src="/static/lk/iconNotApproval.svg" alt="Иконка" />}
        {(statusForm === 'success' || score >= 70) && <img src="/static/lk/iconDoneBig.svg" alt="Иконка" />}

        <h4
          className={cn(s.subtitle, {
            [s.subtitle__approval]: statusForm === 'success',
            [s.subtitle__notApproval]: statusForm === 'failed',
          })}
        >
          {width > 575 ? `${clientWithoutCours ? 11 : 12}. Экзамен` : `Шаг ${clientWithoutCours ? 11 : 12}`}
        </h4>
      </div>

      {(statusForm === 'success' || score >= 70) && (
        <h1 className={`${s.title} lk-title`}>Экзамен пройден успешно!</h1>
      )}

      {statusForm === 'failed' && <h1 className={`${s.title} lk-title`}>Экзамен не сдан :(</h1>}

      {statusForm == 'failed' && (
        <p style={{ marginBottom: '43px', maxWidth: '600px' }} className={s.descr}>
          К сожалению, ты допустил слишком много ошибок. Твой результат — {score} из 100 баллов.{' '}
          {incorrect.length > 0 ? 'Ошибки следующие:' : ''}
        </p>
      )}

      {(statusForm == 'success' || score >= 70) && (
        <p className={s.descr_approval}>
          Твой результат — {score} из 100 баллов. Теперь ты имеешь право запускать фирменный официальный офис
          Скилла Работа
        </p>
      )}

      <div style={{ display: `${incorrect.length == 0 ? 'none' : 'block'}` }} className={s.window}>
        {incorrect.length > 0 &&
          incorrect.map((item) => {
            return (
              <IncorrectAnswer question={item.question} answers={item.answer} correctAnswers={item.correct} />
            )
          })}
      </div>

      {statusForm === 'failed' ? (
        <img
          className={s.illustration__rejected}
          src={
            width > 575
              ? '/static/lk/illustration/illustrationBad.png'
              : '/static/lk/illustration/illustrationBad-mobile.png'
          }
          alt="Иллюстрация"
        />
      ) : statusForm === 'success' || score >= 70 ? (
        <img
          className={s.illustation__approval}
          src={
            width > 575
              ? '/static/lk/illustration/illustrationThreePeople.png'
              : '/static/lk/illustration/illustrationThreePeople-mobile.png'
          }
          alt="Иллюстрация"
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default Done
