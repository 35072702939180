import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLKContract } from '../../../../store/reducer/lk/contract/selector';
import { setAddActiveLink } from '../../../../store/reducer/lk/sidebar/slice';
import Skeleton from '../../../skeleton/Skeleton';
import s from '../premiumDone/premiumDone.module.scss';
const Done = ({prepayStatus}) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [showContract, setShowContract] = useState(false);
	const { isLoading, contractText } = useSelector(selectLKContract);
	const { date, contractNumber } = useSelector(selectLKContract);
	const dispatch = useDispatch();
	console.log(contractText)

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);


	const goPaymentPage = () => {
		dispatch(setAddActiveLink(9));
		localStorage.setItem('activeLink', JSON.stringify(9));
	};

	const options = {
		day: 'numeric',
		month: 'numeric',
	};

	const dateContract = date;

	const dateContractFormat = new Date(dateContract);

	const dateContractTransform = dateContractFormat.toLocaleDateString(
		'ru-RU',
		options
	);
	const yearNow = new Date().getUTCFullYear();

	return (
		<div className={s.premiumDone}>
			{!showContract ? (
				<>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
						<h4 className={s.subtitle}>{width > 575 ? '8. Договор-оферта' : 'Шаг 8'}</h4>
					</div>

					<h1 className={`${s.title} lk-title`}>Договор подписан!</h1>

					<p className={s.descr2}>
						Текст договора можно посмотреть тут{' '}
						<a
							/* onClick={() => setShowContract(true)} */
							className={s.contract_text}
							target='_blank'
							href='https://skilla.ru/license'
						>
							Лицензионный договор-оферта
							{/* Договор №{contractNumber} от {dateContractTransform}.{yearNow} */}
						</a>
					</p>

					<button onClick={goPaymentPage} className={s.btn}>
						Внести предоплату
					</button>

					{prepayStatus && <div className={s.bottom}>
						<div>
							<img src='/static/lk/iconAlertBlack.svg' alt='иконка' />
							<span>Никакого риска!</span>
						</div>

						<p>
							Skilla вернет 100% предоплаты в любой момент по твоему запросу (Статья
							6 Гарантия возврата средств, пункт 6.1. Договора)
						</p>
					</div>}

					<img
						className={s.illustation}
						src={width > 575 ? '/static/lk/founder2.png' : '/static/lk/founder2-mobile.png'}
						alt='Основатель'
					/>
				</>
			) : !isLoading && contractText ? (
				<div className={s.contract_vipStatus}>
					<button className={s.back} onClick={() => setShowContract(false)}>
						<img src='/static/lk/back.png' alt='Назад' />
					</button>
					<div className={s.inner}>
						<p>{parse(contractText)}</p>
					</div>
				</div>
			) : (
				<Skeleton />
			)}
		</div>
	);
};

export default Done;
