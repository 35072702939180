import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step31 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError88, setError88] = useState(false);
	const [isError89, setError89] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	const schema = yup
		.object({
			['До какого числа необходимо внести обязательные абонентские платежи?']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Какие обязательные ежемесячные платежи по Договору с УК Скилла?']: data['Какие обязательные ежемесячные платежи по Договору с УК Скилла?'],
			['Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?']: data['Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'],
			['До какого числа необходимо внести обязательные абонентские платежи?']: data['До какого числа необходимо внести обязательные абонентские платежи?'],
		},
	});


	const ckbox88 = watch('Какие обязательные ежемесячные платежи по Договору с УК Скилла?');
	const ckbox89 = watch('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?');

	useEffect(() => {
		if (!ckbox88?.length) {
			setError88(true);
		} else {
			setError88(false);
		}
	}, [ckbox88]);

	useEffect(() => {
		if (!ckbox89?.length) {
			setError89(true);
		} else {
			setError89(false);
		}
	}, [ckbox89]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError88 && !isError89) {
			dispatch(setAddStep(32));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(31));
		}
	};

	return (
		<div className={s.step31}>
			<div className={s.wrapper_heading}>
				<h5>Оплаты по Договору</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						88. Какие обязательные ежемесячные платежи по Договору с УК "Скилла"?
					</h4>

					<div className={s.question88}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question88,
								})}
								{...register('Какие обязательные ежемесячные платежи по Договору с УК Скилла?')}
								id='exam881'
								type='checkbox'
								name='Какие обязательные ежемесячные платежи по Договору с УК Скилла?'
								autoComplete='off'
								value='Ежемесячный лицензионный платеж - 9 158 руб.' />
							<label htmlFor='exam881' className={s.label}>
								Ежемесячный лицензионный платеж - 9 158 руб.
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question88,
								})}
								{...register('Какие обязательные ежемесячные платежи по Договору с УК Скилла?')}
								id='exam882'
								type='checkbox'
								name='Какие обязательные ежемесячные платежи по Договору с УК Скилла?'
								autoComplete='off'
								value='Ежемесячный лицензионный платеж - 20 700/30 700 руб.' />
							<label htmlFor='exam882' className={s.label}>
								Ежемесячный лицензионный платеж - 20 700/30 700 руб.
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question88,
								})}
								{...register('Какие обязательные ежемесячные платежи по Договору с УК Скилла?')}
								id='exam883'
								type='checkbox'
								name='Какие обязательные ежемесячные платежи по Договору с УК Скилла?'
								autoComplete='off'
								value='Услуги бухгалтера' />
							<label htmlFor='exam883' className={s.label}>
								Услуги бухгалтера
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question88,
								})}
								{...register('Какие обязательные ежемесячные платежи по Договору с УК Скилла?')}
								id='exam884'
								type='checkbox'
								name='Какие обязательные ежемесячные платежи по Договору с УК Скилла?'
								autoComplete='off'
								value='СЕО- продвижение' />
							<label htmlFor='exam884' className={s.label}>
								СЕО- продвижение
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question88,
								})}
								{...register('Какие обязательные ежемесячные платежи по Договору с УК Скилла?')}
								id='exam885'
								type='checkbox'
								name='Какие обязательные ежемесячные платежи по Договору с УК Скилла?'
								autoComplete='off'
								value='Услуги контактного центра (при несоблюдении рекомендованного рекламного бюджета)' />
							<label htmlFor='exam885' className={s.label}>
								Услуги контактного центра (при несоблюдении рекомендованного рекламного бюджета)
							</label>
						</div>

						<p className={s.errorText2}>
							{isError88 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>
						89. Какие необязательные ежемесячные платежи по Договору с УК "Скилла", выполняются при желании партнера?
					</h4>

					<div className={s.question89}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question89,
								})}
								{...register('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?')}
								id='exam891'
								type='checkbox'
								name='Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'
								autoComplete='off'
								value='Ежемесячный лицензионный платеж - 9 158 руб.' />
							<label htmlFor='exam891' className={s.label}>
								Ежемесячный лицензионный платеж - 9 158 руб.
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question89,
								})}
								{...register('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?')}
								id='exam892'
								type='checkbox'
								name='Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'
								autoComplete='off'
								value='Ежемесячный лицензионный платеж - 20 700/30 700 руб.' />
							<label htmlFor='exam892' className={s.label}>
								Ежемесячный лицензионный платеж - 20 700/30 700 руб.
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question89,
								})}
								{...register('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?')}
								id='exam893'
								type='checkbox'
								name='Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'
								autoComplete='off'
								value='Услуги бухгалтера' />
							<label htmlFor='exam893' className={s.label}>
								Услуги бухгалтера
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question89,
								})}
								{...register('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?')}
								id='exam894'
								type='checkbox'
								name='Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'
								autoComplete='off'
								value='СЕО- продвижение' />
							<label htmlFor='exam894' className={s.label}>
								СЕО- продвижение
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question89,
								})}
								{...register('Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?')}
								id='exam895'
								type='checkbox'
								name='Какие необязательные ежемесячные платежи по Договору с УК Скилла, выполняются при желании партнера?'
								autoComplete='off'
								value='Услуги контактного центра' />
							<label htmlFor='exam895' className={s.label}>
								Услуги контактного центра
							</label>
						</div>

						<p className={s.errorText2}>
							{isError89 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

				<div className={s.form_item}>
					<h4 className={s.form_title}>90. До какого числа необходимо внести обязательные абонентские платежи?</h4>

					<div className={s.question90}>
						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question90,
								})}
								{...register('До какого числа необходимо внести обязательные абонентские платежи?')}
								id='exam901'
								type='radio'
								name='До какого числа необходимо внести обязательные абонентские платежи?'
								autoComplete='off'
								value='До 5 числа каждого месяца на основе предоплаты'
							/>
							<label htmlFor='exam901' className={s.label}>
								До 5 числа каждого месяца на основе предоплаты
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question90,
								})}
								{...register('До какого числа необходимо внести обязательные абонентские платежи?')}
								id='exam902'
								type='radio'
								name='До какого числа необходимо внести обязательные абонентские платежи?'
								autoComplete='off'
								value='До 15 числа каждого месяца на основе предоплаты'
							/>
							<label htmlFor='exam902' className={s.label}>
								До 15 числа каждого месяца на основе предоплаты
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question90,
								})}
								{...register('До какого числа необходимо внести обязательные абонентские платежи?')}
								id='exam903'
								type='radio'
								name='До какого числа необходимо внести обязательные абонентские платежи?'
								autoComplete='off'
								value='В любой день месяца'
							/>
							<label htmlFor='exam903' className={s.label}>
								В любой день месяца
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question90,
								})}
								{...register('До какого числа необходимо внести обязательные абонентские платежи?')}
								id='exam904'
								type='radio'
								name='До какого числа необходимо внести обязательные абонентские платежи?'
								autoComplete='off'
								value='Нет обязательных абонентских платежей'
							/>
							<label htmlFor='exam904' className={s.label}>
								Нет обязательных абонентских платежей
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['До какого числа необходимо внести обязательные абонентские платежи?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
					<Button callback={() => dispatch(setAddStep(30))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 31 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step31;
