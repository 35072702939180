import cn from 'classnames';
import s from '../steps.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	setAddData,
	setAddStep,
} from '../../../../../store/reducer/lk/formExam/slice';

import { selectLkFormExam } from '../../../../../store/reducer/lk/formExam/selector';
import Button from '../../Button/Button';
import Form from '../../Form';

const Step27 = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const dispatch = useDispatch();
	const { data, step } = useSelector(selectLkFormExam);
	const [isError76, setError76] = useState(false);
    const [isError77, setError77] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		localStorage.setItem('stepLkExam', JSON.stringify(step));
		localStorage.setItem('dataExam', JSON.stringify(data));
	}, []);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);
    
	const schema = yup
		.object({
			['Можно ли придумать название бренда для клиентов?']: yup.string(),
            ['Расположение офиса партнера должно быть']: yup.string(),
		})
		.required();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			['Можно ли придумать название бренда для клиентов?']: data['Можно ли придумать название бренда для клиентов?'],
			['Партнерский офис Скилла Работа может быть общей площадью:']: data['Партнерский офис Скилла Работа может быть общей площадью:'],
			['Что самое необходимое должно быть в партнерском офисе Скилла Работа?']: data['Что самое необходимое должно быть в партнерском офисе Скилла Работа?'],
            ['Расположение офиса партнера должно быть']: data['Расположение офиса партнера должно быть'],
		},
	});

	
	const ckbox76 = watch('Партнерский офис Скилла Работа может быть общей площадью:');
    const ckbox77 = watch('Что самое необходимое должно быть в партнерском офисе Скилла Работа?');
	
	useEffect(() => {
		if (!ckbox76?.length) {
			setError76(true);
		} else {
			setError76(false);
		}
	}, [ckbox76]);

    useEffect(() => {
		if (!ckbox77?.length) {
			setError77(true);
		} else {
			setError77(false);
		}
	}, [ckbox77]);

	const onSubmit = data => {
		setIsSendForm(true);

		if (!isError77 && !isError76) {
			dispatch(setAddStep(28));
			dispatch(setAddData(data));
			setIsSendForm(false);

			localStorage.setItem('dataExam', JSON.stringify(data));
			localStorage.setItem('stepLkExam', JSON.stringify(27));
		}
	};

	return (
		<div className={s.step27}>
      <div className={s.wrapper_heading}>
			  <h5>Бренд</h5>
			</div>
			<Form onSubmit={handleSubmit(onSubmit)}>

                <div className={s.form_item}>
					<h4 className={s.form_title}>75. Можно ли придумать название бренда для клиентов?</h4>

					<div className={s.question75}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question75,
								})}
								{...register('Можно ли придумать название бренда для клиентов?')}
								id='exam751'
								type='radio'
								name='Можно ли придумать название бренда для клиентов?'
								autoComplete='off'
								value='Нет, мне присваивают уже готовый  бренд'
							/>
							<label htmlFor='exam751' className={s.label}>
							Нет, мне присваивают уже готовый  бренд
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question75,
								})}
								{...register('Можно ли придумать название бренда для клиентов?')}
								id='exam752'
								type='radio'
								name='Можно ли придумать название бренда для клиентов?'
								autoComplete='off'
								value='Да, под мое название мне создадут сайт, брендбук, корпоративную почту и т.д.'
							/>
							<label htmlFor='exam752' className={s.label}>
							Да, под мое название мне создадут сайт, брендбук, корпоративную почту и т.д.
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['Можно ли придумать название бренда для клиентов?'] ? 'Необходимо указать' : ''}
					</p>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					76. Партнерский офис Скилла Работа может быть общей площадью:
					</h4>

					<div className={s.question76}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question76,
								})}
								{...register('Партнерский офис Скилла Работа может быть общей площадью:')}
								id='exam761'
								type='checkbox'
								name='Партнерский офис Скилла Работа может быть общей площадью:'
								autoComplete='off'
								value='10 кв. м'/>
							<label htmlFor='exam761' className={s.label}>
							10 кв. м
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question76,
								})}
								{...register('Партнерский офис Скилла Работа может быть общей площадью:')}
								id='exam762'
								type='checkbox'
								name='Партнерский офис Скилла Работа может быть общей площадью:'
								autoComplete='off'
								value='15 кв. м'/>
							<label htmlFor='exam762' className={s.label}>
							15 кв. м
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question76,
								})}
								{...register('Партнерский офис Скилла Работа может быть общей площадью:')}
								id='exam763'
								type='checkbox'
								name='Партнерский офис Скилла Работа может быть общей площадью:'
								autoComplete='off'
								value='30 кв.м'/>
							<label htmlFor='exam763' className={s.label}>
							30 кв.м
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question76,
								})}
								{...register('Партнерский офис Скилла Работа может быть общей площадью:')}
								id='exam764'
								type='checkbox'
								name='Партнерский офис Скилла Работа может быть общей площадью:'
								autoComplete='off'
								value='50 кв.м'/>
							<label htmlFor='exam764' className={s.label}>
							50 кв.м
							</label>
						</div>

						<p className={s.errorText2}>
							{isError76 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>
					77. Что самое необходимое должно быть в партнерском офисе Скилла Работа?
					</h4>

					<div className={s.question77}>
						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam771'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Объемный логотип с подсветкой Скилла Работа'/>
							<label htmlFor='exam771' className={s.label}>
							Объемный логотип с подсветкой Скилла Работа
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam772'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Соблюдение фирменных цветов бренда на стенах'/>
							<label htmlFor='exam772' className={s.label}>
							Соблюдение фирменных цветов бренда на стенах
							</label>
						</div>

						<div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam773'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Рекламный ролл-ап Скилла Работа'/>
							<label htmlFor='exam773' className={s.label}>
							Рекламный ролл-ап Скилла Работа
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam774'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Стойка ресепшен'/>
							<label htmlFor='exam774' className={s.label}>
							Стойка ресепшен
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam775'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Брендированная карта России'/>
							<label htmlFor='exam775' className={s.label}>
							Брендированная карта России
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam776'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Ростовая фигура персонажа Скилла Работа'/>
							<label htmlFor='exam776' className={s.label}>
							Ростовая фигура персонажа Скилла Работа
							</label>
						</div>

                        <div className={s.checkbox}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question77,
								})}
								{...register('Что самое необходимое должно быть в партнерском офисе Скилла Работа?')}
								id='exam777'
								type='checkbox'
								name='Что самое необходимое должно быть в партнерском офисе Скилла Работа?'
								autoComplete='off'
								value='Площадь офиса от 15 кв.м.'/>
							<label htmlFor='exam777' className={s.label}>
							Площадь офиса от 15 кв.м.
							</label>
						</div>

						<p className={s.errorText2}>
							{isError77 &&
								isSendForm &&
								'Необходимо указать'}
						</p>
					</div>
				</div>

                <div className={s.form_item}>
					<h4 className={s.form_title}>78. Расположение офиса партнера должно быть</h4>

					<div className={s.question78}>
                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question78,
								})}
								{...register('Расположение офиса партнера должно быть')}
								id='exam781'
								type='radio'
								name='Расположение офиса партнера должно быть'
								autoComplete='off'
								value='В центре города'
							/>
							<label htmlFor='exam781' className={s.label}>
							В центре города
							</label>
						</div>

						<div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question78,
								})}
								{...register('Расположение офиса партнера должно быть')}
								id='exam782'
								type='radio'
								name='Расположение офиса партнера должно быть'
								autoComplete='off'
								value='На окраине, стоимость аренды дешевле'
							/>
							<label htmlFor='exam782' className={s.label}>
							На окраине, стоимость аренды дешевле
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question78,
								})}
								{...register('Расположение офиса партнера должно быть')}
								id='exam783'
								type='radio'
								name='Расположение офиса партнера должно быть'
								autoComplete='off'
								value='В пешей доступности от объектов транспортной инфраструктуры'
							/>
							<label htmlFor='exam783' className={s.label}>
							В пешей доступности от объектов транспортной инфраструктуры
							</label>
						</div>

                        <div className={s.radio}>
							<input
								className={cn(s.input, {
									[s.error]: errors.question78,
								})}
								{...register('Расположение офиса партнера должно быть')}
								id='exam784'
								type='radio'
								name='Расположение офиса партнера должно быть'
								autoComplete='off'
								value='В спальном районе города'
							/>
							<label htmlFor='exam784' className={s.label}>
							В спальном районе города
							</label>
						</div>

					</div>

					<p className={s.errorText}>
						{errors['Расположение офиса партнера должно быть'] ? 'Необходимо указать' : ''}
					</p>
				</div>

				<div className={s.btns}>
        <Button callback={() => dispatch(setAddStep(26))} back>
						{width > 575 ? (
							'   Назад'
						) : (
							<img src='/static/lk/IconBackward.svg' alt='назад' />
						)}
					</Button>
					<Button>Далее 27 из 35</Button>
				</div>
			</Form>

			<img
				className={s.illustration}
				src='/static/lk/illustration/illustration.png'
				alt='Иллюстрация'
			/>
		</div>
	);
};

export default Step27;
